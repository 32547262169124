<template>
    <app-wrapper>
        <template v-slot:child>
            <!-- Top content-->
            <InvoiceHeader v-if="plan?.businessPlan !== 'Micro Business Plan'" :contentType="'invoiceSummary'" />

            <InvoiceRecordPaymentModal :closeModal="() => toggleRecordPaymentModal({ status: false, quotation: null })" />

            <template v-if="isLoading"><Spinner /></template>

            <div v-else-if="plan?.businessPlan !== 'Micro Business Plan'">
                <!--Invoice Detail -->
                <div class="w-90 center">
                    <div class="flex mt4">
                        <div class="flex w-10 justify-between">
                            <div :class="$route.path === '/quotation/summary' ? 'active-menu pa3' : 'pa3'">
                                <router-link
                                    :to="{ name: 'InvoiceSummary' }"
                                    :class="$route.path === '/quotation/summary' ? active : ''"
                                    >Invoice</router-link
                                >
                            </div>
                            <div :class="$route.path === '/quotations' ? 'active-menu pa3' : 'pa3'">
                                <router-link
                                    :to="{ name: 'Quotations' }"
                                    :class="$route.path === '/quotations' ? active : ''"
                                    >Quotations</router-link
                                >
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
                <!--Invoice Detail -->

                <!-- Sales, purchase expenses others -->
                <div class="w-90 center pt4">
                    <!-- <div
                        class="pv4 flex flex-wrap justify-between items-center"
                        style="border-top: 1px solid #E3E8EE; border-bottom: 1px solid #E3E8EE;"
                    > -->
                        <div class="flex scrollmenu w-100 link-container mb4">
                            <router-link
                                to="#"
                                @click="changeActiveTable('allQuotes')"
                                :class="state.tableType === 'allQuotes' ? 'active-header mr3' : 'mr3'"
                                > <div class="pv3 pa3 w-100 b">All</div></router-link
                            >
                            <router-link
                                to="#"
                                @click="changeActiveTable('approved')"
                                :class="state.tableType === 'approved' ? 'active-header mr3' : 'mr3'"
                                > <div class="pv3 pa3 w-100 b">Approved</div></router-link
                            >
                            <router-link
                                to="#"
                                @click="changeActiveTable('pending')"
                                :class="state.tableType === 'pending' ? 'active-header pr3' : 'mr3'"
                                > <div class="pv3 pa3 w-100 b">Pending</div></router-link
                            >
                        </div>
                        <div class="flex flex-wrap justify-between items-center w-50-l w-100 mv4 " style="margin-left: auto;">
                            <select v-model="state.dateFilter" @change="changeDateFilter" class="card-box mb3 mb0-l" id="" style="padding: 13px 0;">
                                <option value="">All</option>
                                <option value="last7Days">Last 7days</option>
                                <option value="currentMonth">This Month</option>
                                <option value="lastMonth">Last Month</option>
                            </select>
                            <input
                                type="date"
                                name=""
                                class="input-style card-box mb3 mb0-l"
                                placeholder="Search..."
                                v-model="state.dateRange.startDate"
                                style="padding: 13px 0;"
                            />
                            <input
                                type="date"
                                name=""
                                class="input-style card-box mb3 mb0-l"
                                placeholder="Search..."
                                v-model="state.dateRange.endDate"
                                @change="changeDateSearch"
                                style="padding: 13px 0;"
                            />
                            <!-- <div class="searchmenu card-box">
                                <img src="imgs/search.svg" class="pa1" alt="" />
                                <input class="searchBox pl2" type="text" name="" v-model="state.searchBox" placeholder="Search..." />
                            </div> -->
                            <div class="filter card-box" style="padding: 13px 0;">
                                <div class="tc flex items-center">
                                    <img src="imgs/filter_list.svg" class="pl3" alt="" />
                                    <router-link to="#" @click="filterTable()" class="pl4">Filter</router-link>
                                </div>
                            </div>
                        </div>
                    <!-- </div> -->
                </div>

                <!-- Sales, purchase expenses others -->

                <div class="scrollytable w-90 center">
                    <table class="w-100 tl mt3" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="pb3 pr3 box-border-bottom tl">Number</th>
                                <th class="pb3 pr3 box-border-bottom tl">Ref</th>
                                <th class="pb3 pr3 box-border-bottom tl">Bill To</th>
                                <th class="pb3 box-border-bottom tl pr2">Issue date</th>
                                <th class="pb3 pr3 box-border-bottom tl">Due Date</th>
                                <th class="pb3 pr3 box-border-bottom tl">Amount</th>
                                <th class="pb3 box-border-bottom tl">No. of Days Due</th>
                                <th class="pb3 box-border-bottom tl">Status</th>
                                <th class="pb3 box-border-bottom tl">Actions</th>
                            </tr>
                        </thead>
                        <tbody v-if="state.activeTable.length">
                            <tr v-for="quotation of state.activeTable" v-bind:key="quotation" class="font-w1">
                                <td class="pv3 pr3 box-border-bottom tl b">
                                    <router-link :to="{ name: 'Quotation', params: { id: quotation?._id } }">
                                        {{ quotation.number }}
                                    </router-link>
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl b">
                                    <router-link :to="{ name: 'Quotation', params: { id: quotation?._id } }">
                                        {{ quotation.reference }}
                                    </router-link>
                                </td>
                                <td class="pv3 pr4 box-border-bottom tl b">
                                    <router-link
                                        v-if="quotation?.customer?.id"
                                        :to="{ name: 'Entity', params: { id: quotation?.customer?.id } }"
                                    >
                                        {{ quotation?.customer?.name }}
                                    </router-link>
                                    <span v-else>{{ quotation?.customer?.name }}</span>
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(quotation.dateIssued) }}</td>
                                <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(quotation.dateDue) }}</td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    {{ formatAmount(quotation.totalAmount, $store.state.Settings?.currency) }}
                                </td>
                                <td
                                    v-if="
                                        quotation.status.includes('paid') ||
                                            quotation.status.includes('void') ||
                                            quotation.status === 'draft'
                                    "
                                    class="pv3 pr3 box-border-bottom tc"
                                >
                                    -
                                </td>
                                <td v-else class="pv3 pr3 box-border-bottom tc">
                                    <span :class="{ 'text-danger': getDaysFromToday(new Date(quotation.dateDue)) < 0 }">{{
                                        getDaysFromToday(new Date(quotation.dateDue)) || 0
                                    }}</span>
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    {{
                                        quotation.status.includes('void')
                                            ? 'Voided'
                                            : quotation.status.includes('paid')
                                            ? 'Paid'
                                            : quotation.status.includes('part_payment')
                                            ? 'Part payment'
                                            : quotation.status.includes('writeOff')
                                            ? 'writeOff'
                                            : state.tableType == 'allQuotes'
                                            ? quotation.status
                                            : tableStatus[state.tableType]
                                    }}
                                </td>
                                <td class="items-center pv3 pr3 box-border-bottom tc">
                                    <div class="dropdown" style="position:relative">
                                        <a
                                            class="dropdown-togglex "
                                            href="#"
                                            role="button"
                                            id="dropdownMenuLink"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" class="fa-2x" />
                                        </a>

                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <li>
                                                <router-link
                                                    :to="{ name: 'Quotation', params: { id: quotation?._id } }"
                                                    class="dropdown-itemx"
                                                >
                                                    View
                                                </router-link>
                                            </li>
                                            <li v-if="quotation.status === 'draft' || quotation.status === 'pending'">
                                                <router-link
                                                    :to="{ name: 'EditQuote', params: { id: quotation?._id } }"
                                                    class="dropdown-itemx"
                                                >
                                                    Edit
                                                </router-link>
                                            </li>
                                            <li v-if="quotation.status === 'pending'">
                                                <a href="" @click.prevent="approveQuote(quotation)">
                                                    Convert to Invoice
                                                </a>
                                            </li>
                                            <li v-if="quotation.status === 'pending'">
                                                <a href="" @click.prevent="deletePendingInvoice(quotation._id)">
                                                    Delete
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="9">
                                    <TableEmptyState :data-array="state.activeTable" message="No quotation record" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- pagination -->
                <div v-if="state.activeTable.length" class="w-90 center pb5 font-w1">
                    <div class="flex justify-between items-center pa3" style="background: #F6F8FE;">
                        <div class="flex items-center ">
                            <div class="pr3-l pr2">
                                Showing
                            </div>
                            <!-- w-20 -->
                            <input
                                type="number"
                                name=""
                                class="input-style w-20"
                                :placeholder="state.pageSize ?? state.activeTable.length"
                                style="border: 1px solid rgb(151, 145, 145); background: #ffffff;"
                                v-model="state.pageSize"
                                @change="changePageSize"
                                :min="1"
                            />
                            <div class="pl3 db-l dn">items per page</div>
                        </div>
                        <div class="flex">
                            <router-link to="#" class="active-page-number">{{ state.pageNumber }}</router-link>
                            <!-- <router-link to="#" @click="changePageNumber(state.pageNumber + 1)" class="active-page-number pl2 pl3-l">{{state.pageNumber + 1}}</router-link> -->
                            <router-link v-if="state.totalPages > 1" to="#" @click.prevent="changePageNumber" class="pl2 pl3-l"
                                >Next</router-link
                            >
                            <router-link v-if="state.totalPages > 1" to="#" @click="goLastPage" class="pl2 pl3-l">End</router-link>
                        </div>
                        <!-- Paginate -->
                    </div>
                </div>
                <!-- pagination -->
            </div>
                        
            <div v-if="plan?.businessPlan && plan?.businessPlan === 'Micro Business Plan'">
                <access-denied title="Access Denied" />
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, watch, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
    formatAmount,
    formatQuantity,
    getDaysFromToday,
    formatDate,
    formatDateFilter,
    getThisMonthDateRange,
    getLastMonthDateRange,
    getLastSevenDaysDateRange,
    getTotalPages,
    paginate2
} from '@/utils/lib'
import Swal from 'sweetalert2'
import AppWrapper from '@/layout/AppWrapper'
import AccessDenied from '@/components/AccessDenied'
import InvoiceHeader from '@/views/Invoice/widgets/InvoiceHeader'
import Pagination from '@/components/Pagination'
import InvoiceRecordPaymentModal from '@/components/InvoiceRecordPaymentModal'
import Spinner from '@/components/Spinner'
import TableEmptyState from '@/components/TableEmptyState'
import InvoiceStatus from '@/views/Invoice/widgets/InvoiceStatus'
// import Filter from '@/components/Filter'

export default {
    name: 'InvoiceSummary',
    components: {
        AppWrapper,
        AccessDenied,
        InvoiceHeader,
        Pagination,
        Spinner,
        TableEmptyState,
        InvoiceRecordPaymentModal,
        InvoiceStatus
    },
    setup() {
        const router = useRouter()
        const store = useStore()
        const route = useRoute()
        const isLoading = computed(() => store.state.Invoice.isLoading)
        const invoice = computed(() => store.state.Invoice.invoice)
        const quotationSummary = computed(() => store.state.Invoice.quotationsSummary)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const plan = computed(() => store.state?.Settings?.paymentPlan)
        const disableDeleteButton = ref(false)
        const isVoidModal = ref(false)
        const quotationReference = ref('')

        
        const quotationNumber = computed(() => store.state.Invoice.quotationNumber)
        // initialize the quotation summary

        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth()
        const day = today.getDate()

        const intialState = {
            allQuotesCount: 0,
            allQuotesAmount: 0,
            approvedCount: 0,
            approvedAmount: 0,
            pendingCount: 0,
            pendingAmount: 0,
            invoice: null,

            allQuotes: [],
            approved: [],
            pending: [],

            activeTable: [],
            tableType: 'allQuotes',
            summaryType: 'all',
            dateFilter: '',
            dateSearch: '',
            searchBox: '',
            dateRange: {
                startDate: formatDateFilter(new Date(2020, 0, 1)),
                endDate: formatDateFilter(new Date())
            },

            pageNumber: 1,
            pageSize: 10,
            totalPages: null,

            lastInvoiceNumber: 0
        }

        const state = reactive(intialState) 

        const toggleVoidModal = (modalState = false, e = null) => {
            // console.log(e)
            isVoidModal.value = modalState
            quotationReference.value = e?.reference
            disableDeleteButton.value = false
        }

        const onDeleteInvoice = () => {
            // start
        }
        const sendInvoiceAsPdf = async (quotationID) => {
            store.dispatch('Invoice/sendInvoiceAsPdf', {
                id: quotationID,
                template: 'default'
            })
        }
        const changePageSize = () => {
            if (parseFloat(state.pageSize) === 0) state.pageSize = 1
            let paginationTable = state[state.tableType]
            state.totalPages = getTotalPages(state.activeTable, state.pageSize)
            if (state.pageNumber > state.totalPages) state.pageNumber = state.totalPages
            paginationTable = paginate2(paginationTable, state.pageNumber, state.pageSize)

            state.activeTable = paginationTable
        }

        const changePageNumber = () => {
            if (parseFloat(state.pageNumber) === 0) state.pageNumber = 1
            let paginationTable = state[state.tableType]
            state.pageNumber = state.pageNumber + 1
            // console.log(state.pageNumber, state.pageSize, state.totalPages)
            state.totalPages = getTotalPages(paginationTable, state.pageSize)
            if (state.pageNumber >= parseFloat(state.totalPages)) state.pageNumber = state.totalPages
            paginationTable = paginate2(paginationTable, state.pageNumber, state.pageSize)

            state.activeTable = paginationTable
        }

        // watch for quotation summary
        watch(
            () => quotationSummary.value,
            (newValue, prevValue) => {
                if (newValue !== prevValue) {
                    const allQuotesSummary = newValue.find(item => item.status === 'all')
                    const approvedSummary = newValue.find(item => item.status === 'approved')
                    const pendingSummary = newValue.find(item => item.status === 'pending')

                    state.allQuotesCount = allQuotesSummary.count
                    state.allQuotesAmount = allQuotesSummary.totalAmount
                    state.allQuotes = allQuotesSummary.quotations

                    state.approvedCount = approvedSummary.count
                    state.approvedAmount = approvedSummary.totalAmount
                    state.approved = approvedSummary.quotations

                    state.pendingCount = pendingSummary.count
                    state.pendingAmount = pendingSummary.totalAmount
                    state.pending = pendingSummary.quotations

                    state.activeTable = state[state.tableType]

                    const lastnumber = pendingSummary.count + approvedSummary.count

                    state.lastInvoiceNumber = lastnumber
                }
            }
        )

        // watch for the active table
        watch(
            () => state.activeTable,
            (newValue, prevValue) => {
                if (newValue !== prevValue) {
                    // console.log('active table changed', newValue)
                }
            }
        )

        onMounted(() => {
            // const query = route.query.limit && route.query.page ? { limit: route.query.limit, page: route.query.page } : null
            const query = {
                limit: '',
                page: '',
                date: '',
                type: ''
            }
            store.dispatch('Invoice/getQuotationsSummary', query)
            store.dispatch('Settings/getBankAccounts')
        })

        const changeActiveTable = tableName => {
            if (tableName === 'allQuotes') {
                state.activeTable = state.allQuotes
                state.tableType = 'allQuotes'
            } else if (tableName === 'approved') {
                state.activeTable = state.approved
                state.tableType = 'approved'
            } else if (tableName === 'pending') {
                state.activeTable = state.pending
                state.tableType = 'pending'
            } 
        }

        const changeSummaryTable = summaryType => {
            // display all quotations
            state.summaryType = summaryType
            const query = {
                limit: '',
                page: '',
                date: '',
                type: summaryType
            }

            store.dispatch('getQuotationsSummary', query)
        }

        const tableStatus = {
            pending: 'Pending',
            approved: 'Approved',
            allQuotes: 'All Quotes'
        }
        const filterTable = () => {
            const query = {
                limit: '',
                page: '',
                date: state.dateRange,
                type: state.summaryType,
                search: state.searchBox
            }
            store.dispatch('getQuotationsSummary', query)
        }

        const changeDateFilter = () => {
            if (state.dateFilter === 'currentMonth') {
                // state.dateRange = getThisMonthDateRange()
                const { startDate, endDate } = getThisMonthDateRange()
                state.dateRange.startDate = formatDateFilter(startDate)
                state.dateRange.endDate = formatDateFilter(endDate)
            } else if (state.dateFilter === 'lastMonth') {
                // state.dateRange = getLastMonthDateRange()
                const { startDate, endDate } = getLastMonthDateRange()
                state.dateRange.startDate = formatDateFilter(startDate)
                state.dateRange.endDate = formatDateFilter(endDate)
            } else if (state.dateFilter === 'last7Days') {
                // state.dateRange = getLastSevenDaysDateRange()
                const { startDate, endDate } = getLastSevenDaysDateRange()
                state.dateRange.startDate = formatDateFilter(startDate)
                state.dateRange.endDate = formatDateFilter(endDate)
            } else if (state.dateFilter === '') {
                state.dateRange.startDate = formatDateFilter(new Date(2020, 0, 1))
                state.dateRange.endDate = formatDateFilter(new Date())
            }

            const query = {
                limit: '',
                page: '',
                date: state.dateRange,
                type: state.summaryType,
                search: state.searchBox
            }
            store.dispatch('getQuotationsSummary', query)
        }

        const changeDateSearch = () => {
            const query = {
                limit: '',
                page: '',
                date: state.dateSearch,
                type: state.summaryType,
                search: state.searchBox
            }

            store.dispatch('getQuotationsSummary', query)
        }

        const deletePendingInvoice = id => {
            Swal.fire({
                title: `Are you sure you want to delete this pending quotation?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, Continue',
                denyButtonText: `No, Cancel`
            }).then(result => {
                if (result.isConfirmed) {
                    store.dispatch('Invoice/deletePendingInvoice', id).then(_ => {
                        const query = { limit: '', page: '', date: '', type: '' }
                        store.dispatch('getQuotationsSummary', query)
                    })
                }
            })
        }

        const toggleRecordPaymentModal = payload => {
            store.dispatch('Invoice/showRecordPaymentModal', payload)
        }

        const processInvoiceStatus = (invoice) => {
            if (invoice.initial_deposit === invoice.totalAmount) {
                invoice.status = ['paid']
            } else if (invoice.initial_deposit > 0) {
                invoice.status = ['part_payment']
            } else {
                invoice.status = 'awaiting_payment'
            }

            return invoice
        }

        const approveQuote = quote => {
            Swal.fire({
                title: `Approve Quotation`,
                text: `Are you sure you want to approve this quote - ${quote.reference}?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, Approve!',
                denyButtonText: `No, cancel!`
            }).then(result => {
                if (result.isConfirmed) {
                    const invoice = processInvoiceStatus(state.invoice)
                    invoice.status = 'awaiting_payment'
                    store.dispatch('Invoice/createAndSendInvoice', invoice).then(() => {
                        invoice.status = 'approved'
                        store.dispatch('Invoice/approveQuote', quote._id)
                    })
                }
            })
        }
        onMounted(async () => {
            const storeAction = route.fullPath.startsWith('/quotations')
                ? 'Invoice/getQuotation'
                : 'Invoice/getInvoice'

            await store.dispatch(storeAction, route.params.id).then((data) => {
                state.invoice = data
            })
        })

        return {
            isLoading,
            formatAmount,
            changeActiveTable,
            formatQuantity,
            state,
            getDaysFromToday,
            tableStatus,
            formatDate,
            changeSummaryTable,
            changeDateSearch,
            changeDateFilter,
            filterTable,
            changePageSize,
            changePageNumber,
            approveQuote,
            toggleRecordPaymentModal,
            rolePermissions,
            role,
            deletePendingInvoice,
            toggleVoidModal,
            quotationReference,
            disableDeleteButton,
            isVoidModal,
            onDeleteInvoice,
            sendInvoiceAsPdf,
            plan,
            invoice,
        }
    }
}
</script>

<style>
:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #0d6efd;
    --bs-link-hover-color: #0a58ca;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
}
.directional-up {
    width: 15px;
    height: 15px;
}
.sort1 {
    padding: 6px;
    cursor: pointer;
}
.sort1:hover {
    background-color: rgb(237, 240, 253);
    color: #132c8c;
    border-radius: 5px;
}
.quotation-actions a {
    width: 22px;
}

.icon-80 {
    width: 80%;
}
.active-header {
    background: white;
    color: #132c8c !important;
}
.link-container {
    background: #132c8c;
    color: white;
    width: fit-content;
    border: 2px solid #132c8c;
    border-radius: 20px;
    background-color: #132c8c;
}
.link-container a{
    color: white;
}

@media screen and (max-width: 767px) {
    .scrollytable {
        overflow-x: auto;
        white-space: nowrap !important;
    }
}
</style>
