<template>
    <!-- form modal -->
    <div
        v-if="showRecordPaymentModal"
        tabindex="0"
        @keydown.esc="closeModal"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showRecordPaymentModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="modalRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div class="f3 b">Record Payment - {{ invoice.reference }}</div>
                <div>
                    <router-link to="#">
                        <img :src="require('@/assets/images/Close-icon.svg')" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <div class="pa3 pt0">
                <form class="entity-form" ref="formRef" @submit.prevent="onSubmit">
                    <div class="pt2 w-100 w-100-l pl2-l">
                        <div class="pv2 font-w1">
                            <label for="name">
                                Amount
                                <span
                                    >(Outstanding:
                                    {{
                                        formatAmount(
                                            invoice.totalAmount - state.part_payment.amount - (invoice?.deposit || 0),
                                            $store.state.Settings?.currency
                                        )
                                    }})</span
                                >
                            </label>
                        </div>
                        <CurrencyInput
                            class="product-input"
                            v-model="state.part_payment.amount"
                            :options="numberFormat"
                            placeholder="0.00"
                            required
                        />
                    </div>
                    <div class="pt2 w-100 w-100-l pl2-l">
                        <div class="pv2 font-w1">
                            <label for="name">Date</label>
                        </div>
                        <input
                            type="date"
                            class="product-input"
                            v-model="state.part_payment.date_paid"
                            placeholder="Date paid"
                            required
                        />
                    </div>
                    <div class="pt2 w-100 w-100-l pl2-l">
                        <div class="pv2 font-w1">
                            <label for="name">Balance Due Date</label>
                        </div>
                        <input
                            type="date"
                            class="product-input"
                            v-model="state.part_payment.balance_due_date"
                            placeholder="Balance due date"
                            required
                        />
                    </div>
                    <div class="pt2 w-100 w-100-l pl2-l">
                        <div class="pv2 font-w1">
                            <label for="name">Payment Details</label>
                        </div>
                        <select class="product-input w-100-l w-100" v-model="state.part_payment.payment_details" required>
                            <option value="Cash">Cash</option>
                            <option v-for="(bank, index) in bankAccounts" :key="index">
                                {{ bank?.aliasName || bank?.bankName }}
                            </option>
                        </select>
                    </div>
                    <div class="pa3 flex justify-between">
                        <button type="submit" :disabled="state.disableSubmitButton">
                            {{ state.disableSubmitButton ? 'Please wait...' : 'Add Payment' }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment-timezone'
import CurrencyInput from '@/components/CurrencyInput'
import { formatAmount } from '@/utils/lib'

export default {
    name: 'InvoiceRecordPaymentModal',
    components: { CurrencyInput },
    props: {
        closeModal: Function
    },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true
            }
        }
    },

    setup(props, { emit }) {
        const modalRef = ref(null)
        const store = useStore()
        const showRecordPaymentModal = computed(() => store.state?.Invoice?.showRecordPaymentModal)
        const invoice = computed(() => store.state?.Invoice?.invoiceForPayment)
        const partPaymentType = computed(() => store.state?.Invoice?.invoicePartPaymentType)
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)
        const amountTopay = computed(() => (invoice.value?.totalAmount || 0) - (invoice.value?.deposit || 0))

        const initialState = {
            part_payment: {
                amount: 0,
                date_paid: moment().format('YYYY-MM-DD'),
                balance_due_date: moment().format('YYYY-MM-DD'),
                payment_details: 'Cash'
            },
            disableSubmitButton: false
        }
        const state = reactive(initialState)

        const closeModal = () => {
            state.part_payment.amount = invoice.value?.totalAmount || 0
            state.part_payment.date_paid = moment().format('YYYY-MM-DD')
            state.part_payment.balance_due_date = moment().format('YYYY-MM-DD')
            state.part_payment.payment_details = 'Cash'
            props.closeModal()
        }

        const handleOnClickOutsideModal = e => {
            if (modalRef?.value && !modalRef?.value.contains(e.target)) {
                closeModal()
            }
        }

        watch(
            () => amountTopay.value,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    state.part_payment.amount = newVal
                }
            }
        )

        const onSubmit = async () => {
            state.disableSubmitButton = true
            const amount = parseFloat(state.part_payment.amount)
            const totalDeposit = parseFloat(state.part_payment.amount) + parseFloat(invoice.value?.deposit || 0)

            // if (amount === 0) {
            //     store.dispatch('Alert/setAlert', {
            //         message: 'Amount cannot be zero',
            //         status: false
            //     })
            //     state.disableSubmitButton = false
            //     return
            // }

            if (totalDeposit > invoice.value.totalAmount) {
                store.dispatch('Alert/setAlert', {
                    message: 'Deposit amount exceeds total invoice amount',
                    status: false
                })
                state.disableSubmitButton = false
                return
            }

            invoice.value.status = totalDeposit === invoice.value.totalAmount ? ['paid'] : ['part_payment']

            invoice.value.due_date = state.part_payment.balance_due_date
            invoice.value.last_payment_date = new Date(state.part_payment.date_paid)
            invoice.value.deposit = amount
            invoice.value.credit = true
            invoice.value.glAccounts = [
                {
                    glAccount: state.part_payment.payment_details,
                    glAmount: amount
                }
            ]

            await store
                .dispatch('Invoice/recordPayment', invoice.value)
                .then(response => {
                    state.disableSubmitButton = false
                    if (response.success) closeModal()
                })
                .catch(_ => {
                    state.disableSubmitButton = false
                })
        }

        return {
            state,
            invoice,
            amountTopay,
            partPaymentType,
            bankAccounts,
            showRecordPaymentModal,
            modalRef,
            handleOnClickOutsideModal,
            onSubmit,
            formatAmount
        }
    }
}
</script>

<style>
.entity-form input[type='radio'] {
    background: unset;
    border: none;
    box-shadow: none;
    padding: unset;
    width: unset;
}
</style>
